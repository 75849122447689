<template>
    <v-container>
        <h4 class="mt-4">{{ $t("message.order-details") }}</h4>

        <!-- <FilterSalesOrderDetails class="mb-5" /> -->
        <FormFilterSales class="mb-5" :salesType="'orderDetails'"/>
        
        <!-- DATA TABLE -->
        
        <v-data-table
            :headers="headers"
            :items="salesState"
            class="elevation-0"
            hide-default-header
            items-per-page-all-text
            :hide-default-footer="true"
            disable-pagination
        >
            <template v-slot:header="{ props }" style="min-height: 50px">
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[0].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[1].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[2].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 120px">
                {{ props.headers[3].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 180px">
                {{ props.headers[4].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 120px">
                {{ props.headers[5].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[6].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 120px">
                {{ props.headers[7].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[8].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[9].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[10].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[11].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[12].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 250px">
                {{ props.headers[13].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[14].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 200px">
                {{ props.headers[15].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 200px">
                {{ props.headers[16].text }}
                </th>
            </template>
        </v-data-table>

        <!-- <LineChart :salesData="dataRaw" :amount="resultAmount" /> -->
        <!-- <v-row class="mt-5">
            <v-col md="3">
                <TableSales />
            </v-col>
            <v-col>
                <PieChart :filterSales="pieChartData" />
            </v-col>
        </v-row> -->
        <!-- <div id="charts">
            <div id="chart1">
            <apexchart
                type="line"
                height="230"
                :options="chartOptionsArea"
                :series="series"
            />
            </div>
        </div> -->
        <!-- <div id="chart1">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div> -->
    </v-container>
</template>

<script>
      /* HIDE FOR A WHILE */
// import LineChart from '../components/LineChart.vue'
// import PieChart from '../components/PieChart.vue'
// import TableSales from '../components/TableSales.vue'
import FilterSalesOrderDetails from '../components/FilterSalesOrderDetails.vue'
import FormFilterSales from '../components/FormFilterSales.vue';
import moneyFormat from '../helpers/moneyFormat'
export default {
    name: "SalesOrderDetails",
    components: {
        // LineChart,
        // PieChart,
        // TableSales,
        FilterSalesOrderDetails,
        FormFilterSales
    },
    data() {
        return {
            resultDate: [],
            resultAmount: [],
            lineChartData: {},
            pieChartData: {}

            /* EXAMPLE CHART */
            // series: [{
            //     data: this.generateDayWiseTimeSeries(
            //         new Date("11 Feb 2017").getTime(),
            //         185,
            //         {
            //         min: 30,
            //         max: 90
            //         }
            //     )
            //     }
            // ],
            // chartOptionsArea: {
            //     chart: {
            //     id: "chartArea",
            //     toolbar: {
            //         autoSelected: "pan",
            //         show: false
            //     }
            //     },
            //     colors: ["#546E7A"],
            //     stroke: {
            //     width: 3
            //     },
            //     dataLabels: {
            //     enabled: false
            //     },
            //     fill: {
            //     opacity: 1
            //     },
            //     markers: {
            //     size: 0
            //     },
            //     xaxis: {
            //     type: "datetime"
            //     }
            // },
            // chartOptionsBrush: {
            //     chart: {
            //     id: "chartBrush",
            //     brush: {
            //         target: "chartArea",
            //         enabled: true
            //     },
            //     selection: {
            //         enabled: true,
            //         xaxis: {
            //         min: new Date("19 Jun 2017").getTime(),
            //         max: new Date("14 Aug 2017").getTime()
            //         }
            //     }
            //     },
            //     colors: ["#008FFB"],
            //     fill: {
            //     gradient: {
            //         enabled: true,
            //         opacityFrom: 0.91,
            //         opacityTo: 0.1
            //     }
            //     },
            //     xaxis: {
            //     type: "datetime",
            //     tooltip: {
            //         enabled: false
            //     }
            //     },
            //     yaxis: {
            //     tickAmount: 2
            //     }
            // }

            // series: [{
            //     name: "Desktops",
            //     data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
            // }],
            // chartOptions: {
            //     chart: {
            //     height: 350,
            //     type: 'line',
            //     zoom: {
            //         enabled: false
            //     }
            //     },
            //     dataLabels: {
            //     enabled: false
            //     },
            //     stroke: {
            //     curve: 'straight'
            //     },
            //     title: {
            //     text: 'Product Trends by Month',
            //     align: 'left'
            //     },
            //     grid: {
            //     row: {
            //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            //         opacity: 0.5
            //     },
            //     },
            //     xaxis: {
            //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            //     }
            // },
            
        }
    },
    methods: {

        /* EXAMPLE CHART */
        // generateDayWiseTimeSeries: function(baseval, count, yrange) {
        //   var i = 0;
        //   var series = [];
        //   while (i < count) {
        //     var x = baseval;
        //     var y =
        //       Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
        //       yrange.min;

        //     series.push([x, y]);
        //     baseval += 86400000;
        //     i++;
        //   }

        //   return series;
        // }
        dispatchSales() {
            this.$store.dispatch("sales_module/fetchSalesOrderDetails")
        }
    },
    computed: {
        salesState() {
            let dataSales = this.$store.state.sales_module.salesResult;
            
            if(dataSales) {
                dataSales.map((sales) => {
                    sales.total_order_price = moneyFormat(sales.total_order_price)
                    sales.price = moneyFormat(sales.price)
                    sales.priceXqty = moneyFormat(sales.priceXqty)
                })
            }

            return dataSales;
        },
        headers() {
            return [
                { text: "ID", value: "order_id", align: "center" },
                { text: this.$t("message.label-invoice"), value: "invoice_no", align: "center" },
                { text: this.$t("message.label-order-total-fee"), value: "total_order_price", align: "center" },
                { text: this.$t("message.label-item-id"), value: "item_id", align: "center" },
                { text: this.$t("message.label-product-name"), value: "item_name", align: "center" },
                { text: this.$t("message.label-item-code"), value: "item_code", align: "center" },
                { text: this.$t("message.label-shop"), value: "store_name", align: "center" },
                { text: this.$t("message.label-sales-price"), value: "price", align: "center" },
                { text: this.$t("message.label-sales-tax"), value: "tax", align: "center" },
                { text: this.$t("message.label-sales-qty-actual"), value: "qty_actual", align: "center" },
                { text: this.$t("message.label-sales-quantity"), value: "qty", align: "center" },
                { text: this.$t("message.label-sales-amountxtax"), value: "priceXqty", align: "center" },
                { text: this.$t("message.label-sales-tax"), value: "taxXqty", align: "center" },
                { text: this.$t("message.label-sales-tax-category"), value: "tax_category", align: "center" },
                { text: this.$t("message.label-sales-tax-rate"), value: "tax_rate", align: "center" },
                { text: this.$t("message.label-sales-order-datetime"), value: "created_at", align: "center" },
                { text: this.$t("message.delivery-date"), value: "delivery_datetime", align: "center" },
            ]
        }
    },
    created() {
        this.dispatchSales();
        // this.fetchSalesOrderDetails()
    }
}
</script>

<style scoped>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
</style>