<template>
    <div>
        <v-row no-gutters align="center" justify="space-between">
            <v-col cols="12" md="8">
                <v-row>
                    <v-col cols="12" md="6" xs="4">
                        <span>{{ $t("message.label-request-datetime") }}</span>
                        <v-row no-gutters class="">
                            <v-col class="mt-1">
                                <v-menu
                                    v-model="modalDateFrom"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filter.dateFrom"
                                            outlined
                                            class=""
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.dateFrom" @input="modalDateFrom = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col class="minus-amount">
                                <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                            </v-col>

                            <v-col class="mt-1">
                                <v-menu
                                    v-model="modalDateTo"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filter.dateTo"
                                            outlined
                                            class=""
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.dateTo" @input="modalDateTo = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3" xs="4" class="">
                        <v-label>{{ $t("message.order-or-delivery") }}</v-label>
                        <v-select
                        :items="statusType"
                        class="input-class mt-1"
                        v-model="filter.status.value"
                        item-text="text"
                        item-value="value"
                        outlined
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="4">
                <v-row class="d-flex" justify="end">
                <v-col cols="12" md="6" xs="4" style="width: fit-content">
                    <v-btn rounded color="primary" @click="submitFilterSales()">
                        <v-icon left>
                            mdi-magnify
                        </v-icon>
                    {{ $t("message.btn-update") }}
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6" style="width: fit-content; padding-left: 0px">
                    <v-btn rounded color="primary" @click="exportCsv">
                        <v-icon left>
                            mdi-download                                
                        </v-icon>
                    {{ $t("message.btn-download") }}
                    </v-btn>
                </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "FormFilterSales",
    props: ['salesType'],
    data() {
        return {
            modalDateFrom: false,
            modalDateTo: false,
            filter: {
                dateFrom: "",
                dateTo: "",
                status: { id: "1", value: "orderdate" }
            },
            dataType: 'orderdate'
        }
    },
    computed: {
        statusType() {
            return [
                { id: "1", text: this.$t("message.order-date"), value: "orderdate" },
                { id: "2", text: this.$t("message.delivery-date"), value: "deliverydate" },
            ]
        }
    },
    methods: {
        submitFilterSales() {
            this.initFilter();
        },
        async initFilter() {
            if(this.salesType == 'orderDetails') {
                await this.$store.dispatch("sales_module/fetchSalesOrderDetails", {
                    dateType: this.filter.status.value,
                    datefrom: this.filter.dateFrom !== null ? this.filter.dateFrom : "",
                    dateto: this.filter.dateTo !== null ? this.filter.dateTo : "",
                })
            } else {
                await this.$store.dispatch("sales_module/fetchSalesOrders", {
                    dateType: this.filter.status.value,
                    datefrom: this.filter.dateFrom !== null ? this.filter.dateFrom : "",
                    dateto: this.filter.dateTo !== null ? this.filter.dateTo : "",
                })
            }
        },
        exportCsv() {
            if(this.salesType == 'orderDetails') {
                return this.$store.dispatch("sales_module/fetchExportCsv", {
                dateType: this.filter.status.value,
                datefrom: this.filter.dateFrom !== null ? this.filter.dateFrom : "",
                dateto: this.filter.dateTo !== null ? this.filter.dateTo : "",
                })
            } else {
                return this.$store.dispatch("sales_module/fetchExportCsvSalesOrders", {
                dateType: this.filter.status.value,
                datefrom: this.filter.dateFrom !== null ? this.filter.dateFrom : "",
                dateto: this.filter.dateTo !== null ? this.filter.dateTo : "",
                })
            }
        }
    },
    created() {
        this.initFilter()
    }
}
</script>

<style>
.v-input .v-input__control .v-text-field__details {
  /* centering text field filter order */
  /* display: none; */
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
</style>